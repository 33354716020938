import '../css/orderItem.scss'
import { useEffect, useState } from 'react'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import blank_image_dark from '../images/blank_image_dark.png'
import blank_image_light from '../images/blank_image_light.png'
import { capitalizeWords } from '../../../services/hooks/useCapitalizeWords'
import { priceFormatter } from '../../../services/hooks/usePriceUIFormatter'

import { setSelectedCategorySectionId } from '../../../services/store/ordering/menu/categories/selectedCategorySectionIdSlice'
import StaticRoutePath from '../../../routing/StaticRoutePath'
import { useCachedImage } from '../../../services/hooks/useCachedImages'
import { setSelectedItemId } from '../../../services/store/ordering/GuestOrder/OrderSlice'
import {
  setCartButtonDisplay,
  setCartItemCategoryId,
} from '../../../services/store/ordering/cart/CartButtonSlice'
import { APP_GLOBAL_ON } from '../../../config/constants'
import { toQueryString } from '../../../common/Utilities'
import {
  getMenuItemName,
  isStoreOnline,
  checkInTime,
  getMenuItemIncludedMods,
  textLimit,
} from '../common/utilities'
import { addItemToCart } from '../../../services/store/ordering/cart'
import { itemObject } from '../../../services/store/ordering/menu/categories'
import Swal from 'sweetalert2'
import { useAppAlerts } from '../../../utilities/alerts'
import { useIntl } from 'react-intl'

// will want this to match close to the API response to map with
interface OrderItemCardProps {
  itemName: string
  itemDescription?: string
  itemPrice: string | number
  itemImage: string | null
  xtraClassName: string
  itemId: number
  categoryName: string
  categoryId: number
  from: string
  categorySectionId: string
}

export const MenuItemCard = ({
  itemName,
  itemDescription,
  itemPrice,
  itemImage,
  itemId,
  categoryName,
  categoryId,
  xtraClassName,
  from,
  categorySectionId,
}: OrderItemCardProps) => {
  const intl = useIntl()
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)

  const base_domain = LocSettings.base_domain
  let navigateTo = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const queryParams = {
    path: itemImage ?? 'null',
    global_on_id: APP_GLOBAL_ON,
    user_id: LocSettings.location.owner,
    cache: 'yes',
  }
  const itemInfoImage = `${
    process.env.REACT_APP_SP_API_URL
  }/download-file?${toQueryString(queryParams)}`
  const imagePlaceholder =
    LocSettings.appearance === 0 ? blank_image_dark : blank_image_light
  const toggle_images = LocSettings.toggle_images === 0
  const capitalizedWord = capitalizeWords(itemName)
  const formattedPrice = priceFormatter(Number(itemPrice))
  const limitedDescription = textLimit(itemDescription ?? '')
  const [isLoading, imageSrc] = useCachedImage(itemInfoImage, imagePlaceholder)
  let menuCategoriesData = useSelector(
    (state: RootState) => state.menuCategories.data
  )
  const { fireErrorAlert, fireLoadingAlert } = useAppAlerts()

  const handleclick = () => {
    let menuCategory = menuCategoriesData.find(
      (category) => category.id == categoryId
    )
    const locationTimezone = LocSettings.location.localTimeZone
    const locationTime = LocSettings.location.localTime
    let CategoryAvail =
      menuCategory &&
      checkInTime(
        menuCategory.start_time,
        menuCategory.end_time,
        locationTimezone,
        locationTime
      )

    let itemInfo = menuCategory?._embedded.items.find(
      (item) => item.id == itemId
    )
    let isModifierOptional = false

    if (itemInfo && menuCategory) {
      isModifierOptional =
        itemInfo?._embedded.mod_groups.filter((group) => group.option == 3)
          .length == itemInfo?._embedded.mod_groups.length
      if (isModifierOptional) {
        if (itemInfo!.price_per_unit <= 0) {
          fireErrorAlert(
            intl.formatMessage({ id: 'olo.menuitem.invalid_price' })
          )
          return
        }
        fireLoadingAlert(
          intl.formatMessage({ id: 'olo.menuitem.adding_item_to_cart' })
        )
        isStoreOnline(LocSettings, (isOnline) => {
          if (isOnline) {
            if (CategoryAvail) {
              const derivedItemName = getMenuItemName(itemInfo!)
              getMenuItemIncludedMods(
                itemInfo!.id,
                LocSettings,
                (includedModifiers) => {
                  Swal.close()

                  dispatch(
                    addItemToCart({
                      id: Date.now() + Math.random(),
                      id_external: itemInfo!.id_external,
                      category_id: menuCategory!.id,
                      locId: LocSettings.id,
                      itemId: itemInfo!.id,
                      itemName: derivedItemName,
                      itemPrice: itemInfo!.price_per_unit,
                      itemUrl: itemInfo!.image ?? '',
                      itemQty: 1,
                      categoryName: menuCategory!.name,
                      categorySectionId: categorySectionId,
                      mods: includedModifiers ?? [],
                      comment: '',
                      taxRate: itemInfo!._embedded.tax_rate
                        ? itemInfo!._embedded.tax_rate?.rate_dec ??
                          itemInfo!._embedded.tax_rate.rate / 100
                        : 0,
                    })
                  )
                }
              )
            } else {
              fireErrorAlert(
                intl.formatMessage({ id: 'olo.item_not_available' })
              )
            }
          } else {
            fireErrorAlert(intl.formatMessage({ id: 'olo.ordering_offline' }))
          }
        })
      } else {
        if (itemInfo!.price_per_unit < 0) {
          fireErrorAlert(
            intl.formatMessage({ id: 'olo.menuitem.invalid_price' })
          )
          return
        }
      }
    }
    if (!isModifierOptional) {
      dispatch(setSelectedCategorySectionId(categorySectionId))
      dispatch(setSelectedItemId(itemId))
      dispatch(setCartItemCategoryId(categoryId))

      dispatch(setCartButtonDisplay(false))
      navigateTo(base_domain + StaticRoutePath.OLO_ITEM_DETAILS, {
        state: { itemId, categoryName, categorySectionId },
      })
    }
  }
  return (
    <div
      onClick={handleclick}
      className={`${from == 'view-category' ? 'col' : 'itemslider'}`}
    >
      <div
        className={`cursor-pointer card no-box-shadow ${
          from == 'view-category'
            ? `${xtraClassName} view_all_item `
            : `${xtraClassName} order-item-card-height `
        } ${
          toggle_images
            ? !itemDescription
              ? 'without-image'
              : 'without-image-description'
            : ''
        }`}
      >
        <div className="card-body p-5 pb-0" style={{ objectFit: 'cover' }}>
          {!toggle_images && isLoading && (
            <img
              className={`w-100 symbol img-min-height ${
                from == 'view-category'
                  ? 'view-category-image'
                  : 'category-image'
              }`}
              src={imageSrc}
              alt=""
              style={{ objectFit: 'fill' }}
            />
          )}
          {!toggle_images && isLoading === false && (
            <img
              src={imageSrc}
              className="symbol w-100 category-image img-min-height"
              style={{ objectFit: 'cover' }}
              onError={({ currentTarget }) => {
                //console.log(currentTarget.onerror)
                currentTarget.onerror = null // prevents looping
                currentTarget.src = imagePlaceholder
              }}
            />
          )}
          <div className="card-body px-0 pb-0 pt-8 text-center category-dec">
            <p className="fw-bold text-gray-800 cursor-pointer fs-3 fs-xl-1 mb-0 item-name">
              {capitalizedWord}
            </p>
          </div>
        </div>

        <div className="text-center pb-3">
          <small className="fw-bold text-primary cursor-pointer fs-3 fs-xl-1">
            ${formattedPrice}
          </small>
        </div>

        {toggle_images && (
          <div className="text-center">
            <small className="text-gray-700">{limitedDescription}</small>
          </div>
        )}
      </div>
    </div>
  )
}
