import { getBorderColor, getCorporateBasedBtnColor } from "./helpers";
import { LocationCardProps } from "./_models";

export const LocationCard: React.FC<LocationCardProps> = ({corporate, location, isSelected = false, onOrderPickeup, onSelect}) => {

    const isMapLayout = () => {
        return onSelect == null
    }

    return (
      <div className={`${!isMapLayout() ? 'px-5 pt-5 mb-5':'px-2 text-black'} d-flex align-items-start`} style={{ border: !isMapLayout() ? '1px solid' : '0px', borderRadius: '5px', maxWidth: isMapLayout() ? '370px' : 'auto', borderColor: isSelected ? getBorderColor(corporate) : corporate.appearance == 0 ? "#515151" : "#BDBDBD" }} onClick={()=>{
        if(onSelect)
            onSelect(location)
    }}>
        <div style={{ flex: '2 1 0%' }}>
          <div className="text-left">
            <div className="mb-0 fs-4 fw-bold">{location.name}</div>
          </div>
          <p className="mt-2 fs-6" style={{ lineHeight: '24px' }}>
            <span>{location.address}, </span><br /><span>{location.state}, {location.zip}, {location.country}</span>
            <span>{' '}{location.phone}</span><br />
            <span>{location.hours}</span>
          </p>
        </div>
        <div style={{ flex: '1 1 0%' }} className="text-center d-flex justify-content-end" onClick={()=>{
            onOrderPickeup(location)
        }}>
          <button
            className={`pickupBtn btn btn-primary ${(isMapLayout() && getCorporateBasedBtnColor(corporate))? "border-dark" : "" } py-0 px-2 d-flex align-items-center justify-content-center ${isMapLayout() ? "ms-5" : "ms-2"} ${getCorporateBasedBtnColor(corporate)}`} style={{ lineHeight: "18px", height:"40px", width: isMapLayout() ? '115px' : 'auto', maxWidth:"120px" }}
          >
            ORDER PICKUP
          </button>
        </div>
      </div>
    );
  };
  