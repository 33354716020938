import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonLoader = () => {

    const RepeatElements = () => {
        const elements = [];
        for (let i = 0; i < 10; i++) {
            elements.push(
                <div key={i} className={'itemslider m-5 card'} style={{ width: '170px' }}>
                    <div className='pb-0 w-100' style={{ objectFit: 'cover' }}>
                        <Skeleton height={140} />
                    </div>
                    <div className='px-2 pb-0 pt-8'>
                        <p>
                            <Skeleton count={2} height={14} />
                        </p>
                    </div>
                </div>
            );
        }
        return (
            <div>
                 {elements}
            </div>
        );
    }

    return (
        <>
            {RepeatElements()}
        </>
    )
}
