import React,{useContext, useEffect, useState } from 'react'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useParams,useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
// @ts-ignore
import { constants } from '../../config/constants'
import { HeaderTitleContext } from '../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore'
import { LoadingPage } from '../../modules/widgets/Loaders/LoadingPage'
import './styles/index.scss'
import { ILogoProps, ReceiptLogo } from './components/ReceiptLogo'
import { IReceiptLocation, ReceiptHeader } from './components/ReceiptHeader'
import { ITicketDetails, ReceiptTicketDetails } from './components/ReceiptTicketDetails'
import {useIntl } from 'react-intl'
import { getGuestCCToken } from '../../services/store/guests/credit_card_tokens'
import {getPayments,setPaymentData,setError,getUrlData,iPaymentData, getPaymentsByTransaction} from '../../services/store/ticket/payment'

import {setUrlData} from '../../services/store/ticket'
import {IReceiptPaymentTotals, ReceiptPaymentTotals} from './components/ReceiptPaymentTotals'
import {ReceiptPaymentData} from './components/ReceiptPaymentData'
import {setFlow} from '../../services/SourceSlice'


interface IPaymentReceipt {
    logo: ILogoProps
    location_info: IReceiptLocation    
    ticket_details: ITicketDetails    
    totals: IReceiptPaymentTotals 
    paymentData:iPaymentData       
}
export const ReceiptPayment = () => {
    const intl = useIntl();
    const dispatch = useDispatch<AppDispatch>()    
    const { short_code } = useParams()    
    const { setPageTitle } = useContext(HeaderTitleContext)
    let navigateTo = useNavigate()

    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const loggedInGuest = useSelector((state: RootState) => state.guest)
    const guest_cc_tokens = useSelector((state: RootState) => state.guest_cc_tokens)    
    const payment = useSelector((state: RootState) => state.payment)

    const [locationName, setLocationName] = useState('')

    const base_domain = LocSettings.base_domain
    

    useEffect(() => {
        setPageTitle(intl.formatMessage({ id: constants.staticPageTitle.receipt_payment }));
        if (LocSettings?.location?.location_name) {
            setLocationName(LocSettings.location.location_name)
        }
    }, [])

    useEffect(() => {        
        if (
            Object.keys(loggedInGuest.data).length != 0 &&
            Object.keys(guest_cc_tokens.data).length == 0
        ) {
            dispatch(getGuestCCToken(loggedInGuest.data.id, LocSettings, ''))
        }
        //Refresh Payment if the page refresh
        if (short_code != undefined) {
            dispatch(setPaymentData({}))            
            dispatch(getUrlData(short_code,LocSettings))
            dispatch(setFlow(constants.flow.payment_receipt))
        } else if (payment.PaymentData && Object.keys(payment.PaymentData).length != 0 && payment.isLoading == false) {
            if (!payment.PaymentData?.transaction_id) {
                dispatch(getPayments(payment.PaymentData.payment_id, LocSettings))
            } else {
                dispatch(getPaymentsByTransaction(payment.PaymentData.transaction_id, LocSettings))
            }
        }
    }, [])

    useEffect(() => {
        if (payment.error && Object.keys(payment.error).length != 0) {
            Swal.fire({
                customClass: {
                    confirmButton: 'btn btn-danger btn-sm fs-5',
                },
                icon: 'error',
                background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
                text: payment.error.message,
                confirmButtonText: intl.formatMessage({ id: 'alert.ok_got_it' }),
                width: '29rem',
                heightAuto: false,
            })
            dispatch(setError({}))
            navigateTo(base_domain)
        } else if (
            payment.isLoading === false &&
            payment.PaymentData &&
            Object.keys(payment.PaymentData).length == 0 &&
            payment.fromUrl && 
            Object.keys(payment.fromUrl).length != 0
        ) {
            if (!payment.fromUrl?.transaction_id) {
                dispatch(getPayments(payment.fromUrl.payment_id, LocSettings))
            } else {
                dispatch(getPaymentsByTransaction(payment.fromUrl.transaction_id, LocSettings))
            }
            dispatch(setUrlData({}))
        } else if (
            payment.isLoading === false &&
            payment.PaymentData &&
            Object.keys(payment.PaymentData).length == 0 &&
            payment.fromUrl && 
            Object.keys(payment.fromUrl).length == 0 &&
            short_code != undefined
        ) {
            dispatch(getUrlData(short_code,LocSettings))
            dispatch(setFlow(constants.flow.public_receipt))
        } else if (payment.PaymentData && payment.isLoading === false && Object.keys(payment.PaymentData).length != 0) {      
           
        }
    }, [payment.error, payment.PaymentData, payment.fromUrl])

    if (typeof payment.PaymentData == undefined || payment.isLoading || (payment.PaymentData && Object.keys(payment.PaymentData).length == 0)) {
        return <LoadingPage />
    }    
    const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
     

   const PaymentData = payment.PaymentData as iPaymentData
   const [dateOpenAt, timeOpenAt] = PaymentData && PaymentData.local_created_datetime
      ? PaymentData.local_created_datetime.split(' ')
      : ['date', 'time']
   const PaymentLocation = PaymentData?._embedded.location_info
   const receiptProps: IPaymentReceipt = {
      logo: {
         img_props: {
            logoFilePath: LocSettings.location.logo ?? null,
            global_on_id: GLOBAL_ON_ID,
         },
         location_name: LocSettings.location.location_name,
      },
      location_info: {
         name: PaymentLocation?.name,
         address: PaymentLocation?.address,
         address2: PaymentLocation?.address2,
         state_name: PaymentLocation?.state_name,
         country_name: PaymentLocation?.country_name,
         phone: PaymentLocation?.phone,
         city: PaymentLocation?.city,
         zip: PaymentLocation?.zip,
      footer: PaymentLocation?.footer,},
      ticket_details: {transaction_type_name:PaymentData?.transaction_type_name,
         emp_name: null,
         location_name: PaymentLocation?.name,
         ticket_number: PaymentData?.payment_status,
         opened_date: dateOpenAt,
         opened_time: timeOpenAt,
         voided: 0,
         ReceiptType: 'Payment',
      },
      totals: {
         sub_total: PaymentData?.base_amount,
         tips: PaymentData?.tip,
         total: PaymentData?.amount,
      },
      paymentData: PaymentData,
   }

    let SignatureImage = ''
    if (receiptProps?.paymentData?.signature_url != undefined) {
        const API_URL = process.env.REACT_APP_SP_API_URL
        SignatureImage = `${API_URL}/download-file?path=${receiptProps?.paymentData?.signature_url}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
    }
   return (
      <>
         <table className='container-fluid mw-500px px-0 mt-n5'>
            <tbody>
               <ReceiptLogo
                  location_name={receiptProps.logo.location_name}
                  img_props={receiptProps.logo.img_props}
               />
               <tr className='card  mx-20px mx-md-0'>
                  <td align='left'>
                     <div className='p-5'>
                        <ReceiptHeader
                           displayType={LocSettings.location.receipt_contact_info_opt}
                           props={receiptProps.location_info}
                        />
                        <div className='separator separator-dashed my-3'></div>
                        <div className='w-100'>
                           <ReceiptTicketDetails props={receiptProps.ticket_details} items={null} />
                        </div>
                        <div className="d-flex justify-content-center text-center fw-bold  mx-1">
                            {receiptProps.paymentData?.payment_status}
                        </div>
                        <div className='separator separator-dashed my-3'></div>
                        <ReceiptPaymentTotals props={receiptProps.totals} />
                        <div className='separator separator-dashed my-3'></div>
                        <ReceiptPaymentData props={receiptProps.paymentData} />
                         <div className='separator separator-dashed my-3'></div>
                        <div className="d-flex justify-content-center text-center fw-semibold  mx-1">
                            <span dangerouslySetInnerHTML={{__html: receiptProps.location_info.footer?receiptProps.location_info.footer:''}}></span>
                        </div>
                         {SignatureImage != '' &&
                             <div className='card-body  p-0'>
                                 <div className=' mx-20px mx-md-0'>
                                     <div className='logo'>
                                         <img
                                             src={SignatureImage}
                                             className='h-100px w-100 symbol'
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null // prevents looping
                                                 currentTarget.alt = ''
                                             }}
                                         />
                                     </div>
                                 </div>
                             </div>
                         }
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </>
   )
}
