import {FC, lazy, SetStateAction, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_spc/assets/ts/_utils'
import {WithChildren} from '../../_spc/helpers'
import {LocationRoute} from './LocationRoute'
import {CheckinRedirect} from '../pages/pre-checkin/CheckinRedirect'
import {IdMeCallbackRedirect} from '../pages/singup/idme/IdMeCallbackRedirect'
import {IdTakePhoto} from '../pages/singup/id_photo/IdTakePhoto'
import {DashboardLandingPage} from '../pages/dashboard/DashboardLandingPage'
import {Receipt} from '../pages/receipt/Receipt'
import {ReceiptRedirect} from './receiptRedirect'
import {LoadingPage} from '../modules/widgets/Loaders/LoadingPage'
import {PaymentDone} from '../pages/receipt/PaymentDone'
import {RegistrationController} from '../modules/registration/RegistrationController'
import {NoFeedOverlay} from '../common/camera/overlays/NoFeedOverlay'
import MainMenuController from '../pages/online_ordering/MainMenuController'
import {ConfirmationPage} from '../pages/kiosk/ConfirmationPage'
import {AllMenuItemsView} from '../pages/online_ordering/AllMenuItemsView'
import LandingPreCheckinPage from '../pages/pre-checkin/LandingPreCheckinPage'
import {PreviewReservation} from '../pages/pre-checkin/steps/previewReservation/PreviewReservation'
import {AddGuests} from '../pages/pre-checkin/steps/addGuests/AddGuests'
import {SelectPaymentMethod} from '../pages/pre-checkin/steps/selectPaymentMethod/SelectPaymentMethod'
import {ConfirmationDone} from '../pages/pre-checkin/steps/confirmationDone/ConfirmationDone'
import {OloController} from '../pages/online_ordering/OloController'
import MenuImageCacher from '../services/caches/MenuImageCacher'
import HomeWrapper from '../pages/dashboard/HomeWrapper'
import DeleteAccount from '../pages/delete/DeleteAccount'
import {ReceiptPayment} from "../pages/receipt/ReceiptPayment";
import {TableRedirect} from './TableRedirect'
import CardReviewView from '../modules/registration/views/confirm_pay_method/CardReviewView'
import { CorporateLocationSearch } from '../pages/corporate_landing/CorporateLocationSearch'
import { ItemModifiersProvider } from '../pages/online_ordering/components/ItmeModifiersProvider'

function RedirectToSP() {
  window.location.href = 'https://www.softpointcloud.com'
  return null
}

export const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const OrderCatWrapper = lazy(() => import('../pages/online_ordering/MainMenuController'))
  const ProfilePageWrapper = lazy(() => import('../pages/profile/ProfilePageWrapper'))
  const CheckinPageWrapper = lazy(() => import('../pages/pre-checkin/CheckinPageWrapper'))
  const FindTicketPageWrapper = lazy(() => import('../pages/receipt/FindTicketPageWrapper'))
  const IdMeCallbackPage = lazy(() => import('../pages/singup/idme/IdMeCallbackWrapper'))
  const ClearCallbackPage = lazy(() => import('../../app/modules/registration/views/external/clear/ClearCallback'))
  const ReceiptPageWrapper = lazy(() => import('../pages/profile/components/ReceiptPageWrapper'))
  const TendedBarPage = lazy(() => import('../pages/login/MainPage'))
  const KioskActivationPage = lazy(() => import('../pages/kiosk/ActivateCodeController'))
  const AcceptTermsPage = lazy(() => import('../pages/login/AcceptTerms'))
  const OrderItem = lazy(() => import('../pages/online_ordering/MenuItemView/MenuItemView'))
  const OrderCheckoutPage = lazy(() => import('../pages/online_ordering/OrderCheckoutView'))
  const OrderTypeWrapper = lazy(() => import('../pages/online_ordering/OrderTypeView'))
  const ReservationsPageWrapper = lazy(
    () => import('../pages/reservations/ReservationsPageWrapper')
  )
  const PreCheckinProtectedRoutes = lazy(() => import('./PreCheckinProtectedRoutes'))
  const AddNewCard = lazy(() => import('../pages/receipt/AddNewCard'))
  const MyWallet = lazy(() => import('../pages/receipt/MyWallet'))
  const TestIdentityFormsController = lazy(
    () => import('../modules/registration/views/identity_forms/TestIdentityFormsController')
  )
  const AuthController = lazy(() => import('../modules/auth/AuthController'))
  const ShareGuest = lazy(() => import('../pages/guest/ShareGuest'))
  const TermsConditionsView = lazy(
    () => import('../modules/registration/views/terms/TermsAndConditions')
  )
  const IdentityFormsController = lazy(
    () => import('../modules/registration/views/identity_forms/IdentityFormsController')
  )
  const IdentityVerificationView = lazy(
    () => import('../modules/registration/views/identity_verification/IdentityVerificationView')
  )
  const PaymentPageWrapper = lazy(
    () => import('../modules/registration/views/add_pay_method/PaymentPageWrapper')
  )

  const LoyaltyEnrollmentView = lazy(
    () => import('../modules/registration/views/loyalty_enrollment/LoyaltyEnrollmentView')
  )

  const ReviewSubmission = lazy(() => import('../pages/login/ReviewSubmission'))

  const appEnv = process.env.REACT_APP_ENV
  const inDevEnv = appEnv != undefined && appEnv.toLowerCase() == 'development'

  return (
    <Routes>
      <Route path='checkin' element={<CheckinRedirect />} />
      <Route path='dashboard' element={<RedirectToSP />} />
      <Route path='r/:short_code' element={<ReceiptRedirect />} />
      <Route path='search/:corporate_name' element={<CorporateLocationSearch />} />
      <Route
        path='/idme/callback'
        element={
          <SuspensedView>
            <IdMeCallbackRedirect />
          </SuspensedView>
        }
      />
      {/*<Route element={<MasterLayout/>}>*/}
      {/*    <Route*/}
      {/*        path='/ActivateCodeController' element={*/}
      {/*        <SuspensedView>*/}
      {/*            <KioskActivationPage/>*/}
      {/*        </SuspensedView>*/}
      {/*    }*/}
      {/*    />*/}
      {/*</Route>*/}
      <Route
        path=':location_id/*'
        element={
          <LocationRoute>
            <MenuImageCacher />
            <Routes>
              <Route element={<HomeWrapper />}>
                {inDevEnv && (
                  <Route path='/test_id_reader' element={<TestIdentityFormsController />} />
                )}
                <Route
                  path='/auth'
                  element={
                    <SuspensedView>
                      <AuthController />
                    </SuspensedView>
                  }
                />
                <Route path='table/:table_id' element={
                  <SuspensedView>
                    <TableRedirect />
                  </SuspensedView>
                }/>
                <Route element={<NoFeedOverlay />} path='/auth/no-camera' />
                <Route element={<RegistrationController />} path='/register'>
                  <Route
                    path='share_guest'
                    element={
                      <SuspensedView>
                        <ShareGuest />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='terms'
                    element={
                      <SuspensedView>
                        <TermsConditionsView />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='identity_forms'
                    element={
                      <SuspensedView>
                        <IdentityFormsController />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='identity_verification'
                    element={
                      <SuspensedView>
                        <IdentityVerificationView />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='card_review'
                    element={
                      <SuspensedView>
                        <CardReviewView />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='add_pay_method'
                    element={
                      <SuspensedView>
                        <PaymentPageWrapper />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='loyalty_enrollment'
                    element={
                      <SuspensedView>
                        <LoyaltyEnrollmentView />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='submission_review'
                    element={
                      <SuspensedView>
                        <ReviewSubmission />
                      </SuspensedView>
                    }
                  />
                </Route>
                <Route
                  path='/'
                  element={
                    <SuspensedView>
                      <DashboardLandingPage />
                    </SuspensedView>
                  }
                />

                {/* Pages */}
                <Route path='/dashboard' element={<HomeWrapper />} />

                {/* Lazy Modules */}
                <Route
                  path='/Activate'
                  element={
                    <SuspensedView>
                      <KioskActivationPage />
                    </SuspensedView>
                  }
                />
                <Route path='/activate-done' element={<ConfirmationPage />} />

                <Route
                  path='/idme/callback'
                  element={
                    <SuspensedView>
                      <IdMeCallbackPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/clear/callback'
                  element={
                    <SuspensedView>
                      <ClearCallbackPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/crafted/pages/wizards/*'
                  element={
                    <SuspensedView>
                      <WizardsPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/crafted/account/*'
                  element={
                    <SuspensedView>
                      <AccountPage />
                    </SuspensedView>
                  }
                />
                <Route path='olo' element={<OloController />}>
                  <Route
                    path='type'
                    element={
                      <SuspensedView>
                        <OrderTypeWrapper />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='menu'
                    element={
                      <SuspensedView>
                        <MainMenuController />
                      </SuspensedView>
                    }
                  />

                  <Route
                    path='menu/all_items'
                    element={
                      <SuspensedView>
                        <AllMenuItemsView />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='checkout'
                    element={
                      <SuspensedView>
                        <OrderCheckoutPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='menu/item'
                    element={
                      <SuspensedView>
                        <ItemModifiersProvider>
                        <OrderItem />
                        </ItemModifiersProvider>
                      </SuspensedView>
                    }
                  />
                </Route>

                <Route
                  path='/receipt'
                  element={
                    <SuspensedView>
                      <Receipt />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/find_ticket'
                  element={
                    <SuspensedView>
                      <FindTicketPageWrapper />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/r/:short_code'
                  element={
                    <SuspensedView>
                      <Receipt />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/rp/:short_code'
                  element={
                      <SuspensedView>
                          <ReceiptPayment />
                      </SuspensedView>
                  }
                />
                <Route
                  path='/payment-receipt'
                  element={
                    <SuspensedView>
                      <ReceiptPageWrapper />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/my-wallet'
                  element={
                    <SuspensedView>
                      <MyWallet />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/add-credit-card'
                  element={
                    <SuspensedView>
                      <MyWallet />
                    </SuspensedView>
                  }
                />                
                <Route
                  path='/add_payment_method'
                  element={
                    <SuspensedView>
                      <AddNewCard />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/receipt/payment-done'
                  element={
                    <SuspensedView>
                      <PaymentDone />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/my-account'
                  element={
                    <SuspensedView>
                      <ProfilePageWrapper />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/delete-account'
                  element={
                    <SuspensedView>
                      <DeleteAccount />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/checkin'
                  element={
                    <SuspensedView>
                      <CheckinPageWrapper />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/pre-checkin/*'
                  element={
                    <SuspensedView>
                      <PreCheckinProtectedRoutes>
                        <Routes>
                          <Route path='/' element={<LandingPreCheckinPage />} />
                          <Route path='/preview-reservation' element={<PreviewReservation />} />
                          <Route
                            path='/verify-your-identity'
                            element={<IdentityFormsController />}
                          />
                          <Route path='/add-guests' element={<AddGuests />} />
                          <Route path='/select-payment-method' element={<SelectPaymentMethod />} />
                          <Route path='/confirmation-done' element={<ConfirmationDone />} />
                        </Routes>
                      </PreCheckinProtectedRoutes>
                    </SuspensedView>
                  }
                />
                {/*TODO: Delete all the components and child components from Route: /pre-checkin*/}
                {/*<Route
                                path='/pre-checkin'
                                element={
                                    <SuspensedView>
                                        <LandingPreCheckinPage/>
                                    </SuspensedView>
                                }
                            />*/}
                <Route
                  path='/reservation'
                  element={
                    <SuspensedView>
                      <ReservationsPageWrapper />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/phone_auth'
                  element={
                    <SuspensedView>
                      <TendedBarPage />
                    </SuspensedView> //:
                    //      (<SuspensedView>
                    //      <AcceptTermsPage
                    //      step={0}
                    //      isMember={false}
                    //      userInputPhoneNumber={""}
                    //      setStep={function (value: SetStateAction<number>): void {
                    //          throw new Error('Function not implemented.')
                    //      } }
                    //      setIsMember={function (value: SetStateAction<boolean>): void {
                    //          throw new Error('Function not implemented.')
                    //      } }
                    //      setOpenIdCamera={function (value: SetStateAction<boolean>): void {
                    //          throw new Error('Function not implemented.')
                    //      }}
                    //      setOpenSelfieCamera={function (value: SetStateAction<boolean>): void {
                    //          throw new Error('Function not implemented.')
                    //      }}

                    //      />
                    //  </SuspensedView>)
                  }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
              </Route>

              <Route
                path='/take_id_photo'
                element={
                  <SuspensedView>
                    <IdTakePhoto
                      setOpenIdCamera={function (value: SetStateAction<boolean>): void {
                        throw new Error('Function not implemented.')
                      }}
                      setOpenSelfieCamera={function (value: SetStateAction<boolean>): void {
                        throw new Error('Function not implemented.')
                      }}
                      setStep={function (value: SetStateAction<number>): void {
                        throw new Error('Function not implemented.')
                      }}
                      forCheckin={false}
                      setOpenCamera={function (value: SetStateAction<boolean>): void {
                        throw new Error('Function not implemented.')
                      }}
                    />
                  </SuspensedView>
                }
              />
            </Routes>
          </LocationRoute>
        }
      />
    </Routes>
  )
}

export const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<LoadingPage />}>{children}</Suspense>
}
