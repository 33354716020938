import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../_spc/helpers'
import { getCorporateBasedBtnColor } from './helpers';
import { ICorporate, LocationProps } from './_models';

interface IHeader {
    corporate?: ICorporate
    selectedLocation?: LocationProps;
}

export const Header: React.FC<IHeader> = ({corporate, selectedLocation}) => {
    let corporateName = corporate?.name ?? "Corporate";
    let navigateTo = useNavigate();
    const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
    const SP_API_URL = process.env.REACT_APP_SP_API_URL

    let corporateLogo = ''
    if (corporate?.logo) {
        corporateLogo = `${SP_API_URL}/download-file?path=${corporate?.logo}&global_on_id=${GLOBAL_ON_ID}&user_id=${corporate.owner}`
    }

    const onLogin = () => { 
        navigateTo(`/${selectedLocation!.location_id}/my-account`);
    }

    return (
        <>
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                <div id="kt_header" className="header align-items-stretch" style={{ left: '0px', zIndex: '9999' }}
                >
                    <div
                        className='container-fluid d-flex align-items-stretch justify-content-between'>
                        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 overflow-hidden'>
                            <div className=' symbol symbol-35px symbol-md-40px' >
                                <img
                                    src={corporateLogo ? corporateLogo : toAbsoluteUrl('/media/logos/softpoint_small.svg')}
                                />
                            </div>
                            <div className='fw-bold fs-2 ps-2' style={{ clear: 'both', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap' }}>{corporateName}</div>
                        </div>
                        <div className='d-flex align-items-stretch justify-content-end flex-lg-grow-1'>
                            <div className='d-flex align-items-stretch flex-shrink-0'>
                            </div>
                        </div>
                        {selectedLocation && (
                            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 justify-content-end">
                                <button className={`btn btn-sm btn-primary ${getCorporateBasedBtnColor(corporate)}`} onClick={onLogin} ><FormattedMessage id='common.login' defaultMessage='Log In' /></button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
