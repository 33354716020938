import React, {ReactElement, useEffect, useState} from 'react';
import axios from "axios";
import {useDispatch, useSelector} from 'react-redux';
import Swal, {SweetAlertResult} from "sweetalert2";
import {matchPath, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {StoreLocationSettings} from "../services/AppSettingsSlice";
import {setFlow, setBusinessSource, setLoyaltyEnrollment, setSubFlow, setIframeSessionId} from "../services/SourceSlice";
import {LoadingPage} from "../modules/widgets/Loaders/LoadingPage";
// @ts-ignore
import Cookies from 'js-cookie'
import {constants} from "../config/constants";
import {AppDispatch, RootState} from '../store';
import {logOutGuest, verifyGuestSession} from "../services/store/guests";
import {setReferer} from "../services/store/referer";
import { getMenuCategories, setMenuCategory  } from '../services/store/ordering/menu/categories';
import { useIntl } from 'react-intl';
import {instanceOfSpError, ISpError} from "../common/api_interfaces/Errors";

let isCategoryLoading = false;
export const LocationRoute = ({children}: any) => {
    const intl = useIntl();
    const urlLoc = useLocation();

    let [searchParams] = useSearchParams();
    const source_business = searchParams.get('s');
    const avoid_redirect_param = searchParams.get('avoid_redirect');
    const query_params = searchParams.toString();
    const OLO_ROUTE_PATTERN = '/:location_id/olo/*'
    const ACTIVATE_ROUTE_PATTERN = '/:location_id/activate'
    const TABLE_ROUTE_PATTERN = '/:location_id/table/:table_id'
    const navigateTo = useNavigate()
    const {location_id: location_id_or_name, table_id: table_id} = useParams();

    const lastSegment = urlLoc ? urlLoc.pathname.split("/").pop() : ''
    const dispatch = useDispatch();
    const dispatchApp = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(true)
    const [NoLocationConfig, setNoLocationConfig] = useState(false);
    const {cookies_name} = constants;
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const refStore = useSelector((state: RootState) => state.referer);
    const SourceFlow = useSelector((state: RootState) => state.Source.flow);
    const ticket: any = useSelector((state: RootState) => state.ticket);//Optional
    const guest = useSelector((state: any) => state.guest);
    const GLOBAL_ON = process.env.REACT_APP_GLOBAL_ON
    const menuLocationSettingsId = useSelector((state: RootState) => state.menuCategories.locationSettingsId);


    let softpointSmallLogo = '';

    if (LocSettings?.location?.logo) {
        softpointSmallLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings.location.logo}&global_on_id=${GLOBAL_ON}&user_id=${LocSettings.location.owner}`;
    } else if (LocSettings?.icon) {
        softpointSmallLogo = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${LocSettings?.icon}&global_on_id=${GLOBAL_ON}&user_id=${LocSettings.location.owner}`;
    }

    //MENU CATEGORIES FETCH
    useEffect(() => {
        if(Cookies.get(cookies_name.access_token))
            loadMenuCategories()
    }, [LocSettings])

    const loadMenuCategories = () => {
        const oloEnabled = LocSettings.olo?.allow_ordering
        const locSettingsAreValid = Object.keys(LocSettings).length > 0 && LocSettings?.location?.id != null
        const isRefererIsSameLocation = [LocSettings?.location?.id ? LocSettings?.location?.id.toString() : 0, LocSettings?.domain_name].includes(location_id_or_name)
        if (!isRefererIsSameLocation)
            dispatch(setMenuCategory([]))
        if (locSettingsAreValid && oloEnabled && !isCategoryLoading && isRefererIsSameLocation){ 
            dispatchApp(getMenuCategories(LocSettings))  
            isCategoryLoading = true    
            // console.log('[LR] LocSettings', LocSettings)
            // console.log('[LR] LocSettings length', Object.keys(LocSettings).length)
            // console.log('[LR] LocSettings.location.id', LocSettings.location.id)
            // console.log('[LR] access_token', authToken)
            // console.log('[LR] menuLocationSettingsId != LocSettings.id', menuLocationSettingsId != LocSettings.id)
        }                
    }


    //styles for the popup
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        buttonsStyling: false,
        icon: 'error',
        background: LocSettings.appearance === 1
            ? '#fffff'
            : '#151521',
        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,
    })
    //Popup for error message
    const fireErrorMessage = async (_errorMessage: string): Promise<SweetAlertResult<ReactElement<any, any>>> => swalWithBootstrapButtons.fire({
        html: `<h6 class="swal2-html-container fs-5 mx-0 gray-800-dark">${_errorMessage}</h6>`
    });




    const validateSession = () => {
        dispatchApp(verifyGuestSession(guest))
    }

    validateSession();

    useEffect(() => {

        let currentRoute=refStore.current.split("/").pop();
        let refRoute=refStore.referer.split("/").pop();

        if (currentRoute === "payment-done" || currentRoute === "add_payment_method" || currentRoute === "receipt" || currentRoute === "payment-receipt") {

            if (ticket.isLoading === false && Object.keys(ticket.data).length == 0) {
                navigateTo('/' + location_id_or_name + '/');
            }
        }

    }, [refStore.referer])

    useEffect(() => {
        dispatch(setReferer(urlLoc.pathname))
        const searchParams = new URLSearchParams(urlLoc.search);
        if(lastSegment== "register"){
            let loyalty_enrollment = searchParams.get("loyalty_enrollment")
            dispatch(setLoyaltyEnrollment(loyalty_enrollment))
            let iframe_session_id = searchParams.get("iframe_session_id")
            dispatch(setIframeSessionId(iframe_session_id))
        }
    }, [urlLoc.pathname]);


    useEffect(() => {
        if (guest.isLoading === false && guest.auth.isAuthenticated === true && Object.keys(guest.data).length > 0) {
            console.log("FROM LOCATION: Guest", guest)
            console.log("FROM LOCATION: LocSettings", LocSettings)

            if (guest.data._embedded?.location_groups.length < 1) {

                dispatch(logOutGuest())
                navigateTo('/' + location_id_or_name + '/');
            }

            let selected_location_found = guest.data._embedded.location_groups.find((guest_loc: { location_id: number; }) => guest_loc.location_id == LocSettings.location.id);
            if (selected_location_found == undefined) {
                dispatch(logOutGuest())
                navigateTo('/' + location_id_or_name + '/');
            }
        }
    }, [LocSettings, guest])

    console.log("lastSegment", lastSegment)

    useEffect(() => {
        const pathDidMatchOlo = matchPath(OLO_ROUTE_PATTERN, urlLoc.pathname)
        const pathDidMatchActivate = matchPath(ACTIVATE_ROUTE_PATTERN, urlLoc.pathname)
        const pathDidMatchTable = matchPath(TABLE_ROUTE_PATTERN, urlLoc.pathname)
        if (source_business !== null) {
            Cookies.set(cookies_name.source_of_business, source_business);
            dispatch(setBusinessSource(source_business));
        }

        if (is_numeric(location_id_or_name?.replace('/', ''))) {
            Cookies.remove(cookies_name.location_domain_name)

            let config = {
                headers: {
                    'X-Skip-Interceptor': `true`,
                }
            }

            const API_URL = `${process.env.REACT_APP_SP_API_URL}/locations/${location_id_or_name}/mobile/settings?global_on_id=${GLOBAL_ON}`;

            axios.get(`${API_URL}`, config)
                .then((response) => {
                    let base_domain = `/${location_id_or_name}`;
                    Cookies.set(cookies_name.location_domain_name, location_id_or_name)
                    if (response?.data?.domain_name && response?.data?.domain_name?.trim() !== '') {
                        Cookies.set(cookies_name.location_domain_name, response?.data?.domain_name)
                        base_domain = `/${response?.data.domain_name}`;

                        console.log("base_domain", base_domain)
                    }

                    console.log("base_domain", base_domain)

                    dispatch(StoreLocationSettings({
                        ...response?.data,
                        'base_domain': base_domain
                    }));

                    if (Cookies.get(cookies_name.access_token) == undefined) {
                        let config = {
                            headers: {
                                Authorization: `Bearer ${Cookies.get(cookies_name.temp_access_token)}`,
                                'X-Skip-Interceptor': `true`,
                            }
                        }

                        const API_URL = `${process.env.REACT_APP_SP_API_URL}/auth/mobile-login?global_on_id=${GLOBAL_ON}&location_id=${response?.data?.location?.id}&user_id=${response?.data?.location?.owner}`;
                        axios.post(`${API_URL}`, {}, config)
                            .then((response_login) => {
                                if (response_login?.data?.access_token == undefined) {
                                    Cookies.remove(cookies_name.temp_access_token)
                                    Cookies.remove(cookies_name.access_token)
                                    fireErrorMessage(intl.formatMessage({id:"location.alert.unavailable"}));
                                } else {
                                    Cookies.set(cookies_name.access_token, response_login?.data?.access_token)
                                    loadMenuCategories()
                                    setLoading(false)

                                    if (base_domain?.trim() != '') {
                                        if (lastSegment == "register") {
                                            console.log("register flow before redirection")
                                            dispatch(setFlow("register"));
                                            // navigateTo(base_domain + '/phone_auth');
                                        } else if (lastSegment == location_id_or_name) {
                                            dispatch(setFlow(constants.flow.register));
                                        } else if (pathDidMatchOlo) {
                                            dispatch(setFlow(constants.flow.order));
                                        }else if (pathDidMatchActivate){
                                            dispatch(setFlow(constants.flow.kiosk_checkin))
                                        }else if (pathDidMatchTable){
                                            dispatch(setFlow(constants.flow.table))
                                            dispatch(setSubFlow(constants.flow.table))
                                        } else if (SourceFlow == constants.flow.pre_checkin) {
                                            navigateTo(base_domain + '/' + constants.routes.private.pre_checkin);
                                        } else if (SourceFlow == constants.flow.public_receipt) {
                                            let short_code = ticket.fromUrl.short_code
                                            if(ticket?.fromUrl?.ticket_id > 0) {
                                                navigateTo(`${base_domain}/r/${short_code}?${query_params}`);
                                            } else if(ticket?.fromUrl?.payment_id > 0) {
                                                navigateTo(`${base_domain}/rp/${short_code}?${query_params}`);
                                            }

                                        }
                                    }
                                }
                            })
                            .catch((res) => {
                                const jsonRes: any | ISpError = res.response.data
                                let msg = intl.formatMessage({id:"location.alert.unavailable"});

                                if (instanceOfSpError(jsonRes)) {
                                    msg = jsonRes.error.message
                                }
                                fireErrorMessage(msg)
                            })
                    } else {
                        loadMenuCategories()
                        setLoading(false)
                        if (base_domain?.trim() !== '') {
                            if (lastSegment == "register") {
                                dispatch(setFlow("register"));
                                // navigateTo(base_domain + '/phone_auth');
                            } else if (lastSegment == location_id_or_name) {
                                dispatch(setFlow(constants.flow.register));
                            }else if (pathDidMatchOlo) {
                                dispatch(setFlow(constants.flow.order))
                            } else if (pathDidMatchActivate){
                                dispatch(setFlow(constants.flow.kiosk_checkin))
                            }else if (pathDidMatchTable){
                                dispatch(setFlow(constants.flow.table))
                                dispatch(setSubFlow(constants.flow.table))
                            } else if (SourceFlow == constants.flow.pre_checkin) {
                                navigateTo(base_domain + '/' + constants.routes.private.pre_checkin);
                            } else if (SourceFlow == constants.flow.public_receipt && typeof ticket.fromUrl.short_code != "undefined") {
                                let short_code = ticket.fromUrl.short_code
                                if(ticket?.fromUrl?.ticket_id > 0) {
                                    navigateTo(`${base_domain}/r/${short_code}?${query_params}`);
                                } else if(ticket?.fromUrl?.payment_id > 0) {
                                    navigateTo(`${base_domain}/rp/${short_code}?${query_params}`);
                                }
                            }
                        }
                    }


                })
                .catch((error) => {
                    console.log("error", error)
                    fireErrorMessage(error.response.data?.error.message).then((result) => {
                        console.log('Set No Location');
                        setNoLocationConfig(true);
                    })
                })
        } else {
            if (lastSegment == "register") {
                console.log("register flow before redirection")
                dispatch(setFlow("register"));
                let base_domain = `/${location_id_or_name}`;
                // navigateTo(base_domain + '/phone_auth');
            } else if (pathDidMatchOlo) {
                dispatch(setFlow(constants.flow.order));
            }else if (pathDidMatchActivate){
                dispatch(setFlow(constants.flow.kiosk_checkin))
            }

            let config = {
                headers: {
                    'X-Skip-Interceptor': `true`,
                }
            }

            const API_URL = `${process.env.REACT_APP_SP_API_URL}/mobile/get-location-by-domain/${location_id_or_name}?global_on_id=${GLOBAL_ON}`;
            axios.get(`${API_URL}`, config)
                .then((response) => {
                    dispatch(StoreLocationSettings({
                        ...response?.data,
                        'base_domain': `/${location_id_or_name}`
                    }));

                    if (lastSegment == location_id_or_name) {
                        dispatch(setFlow(constants.flow.register));
                    }

                    if (Cookies.get(cookies_name.access_token) == undefined) {
                        let config = {
                            headers: {
                                Authorization: `Bearer ${Cookies.get(cookies_name.temp_access_token)}`,
                                'X-Skip-Interceptor': `true`,
                            }
                        }

                        const API_URL = `${process.env.REACT_APP_SP_API_URL}/auth/mobile-login?global_on_id=${GLOBAL_ON}&location_id=${response?.data?.location?.id}&user_id=${response?.data?.location?.owner}`;
                        axios.post(`${API_URL}`, {}, config)
                            .then((response) => {
                                if (response?.data?.access_token == undefined) {
                                    Cookies.remove(cookies_name.temp_access_token)
                                    Cookies.remove(cookies_name.access_token)
                                    fireErrorMessage(intl.formatMessage({id:"location.alert.unavailable"}));
                                } else {
                                    Cookies.set(cookies_name.access_token, response?.data?.access_token)
                                    loadMenuCategories()
                                    setLoading(false)
                                }
                            })
                            .catch((error) => {
                                if (avoid_redirect_param == undefined) {
                                    Cookies.remove(cookies_name.temp_access_token)
                                    Cookies.remove(cookies_name.access_token)
                                    window.location.href = `${window.location.origin}/${location_id_or_name}?avoid_redirect=yes`;
                                } else {
                                    fireErrorMessage(intl.formatMessage({id:"location.alert.offline"}))
                                }
                            })
                    } else {
                        setLoading(false)
                        loadMenuCategories()
                    }

                })
                .catch((error) => {
                    console.log("error", error)
                    fireErrorMessage(error.response.data.error.message).then(result => window.location.href = LocSettings.location.website);

                })
        }
    }, []);

    if (loading) {
        if (!NoLocationConfig) {
            return (
                <div className="row w-100">
                    <LoadingPage/>
                </div>
            )
        } else {
            return (<div style={{height: '75vh'}}
                         className="d-flex justify-content-center flex-column align-items-center w-100">
                {
                    softpointSmallLogo ? <img className='h-45px w-45px mb-5 symbol' src={softpointSmallLogo}
                                              onError={({currentTarget}) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.alt = "logo";
                                              }}
                    /> : ''
                }
            </div>);
        }
    }

    return children
}

const is_numeric = (value: string | undefined) => {
    return /^-?\d+$/.test(value as string);
}
