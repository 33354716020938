import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Header } from './Header'
import { LocationCard } from './LocationCard'
import { ICorporate, LocationProps } from './_models'
import axios from 'axios'
// @ts-ignore
import Cookies from 'js-cookie'
import { constants } from '../../config/constants'
import { useDispatch } from 'react-redux'
import { StoreLocationSettings } from '../../services/AppSettingsSlice'
import Swal from 'sweetalert2'
import { AppDispatch } from '../../store'
import { setFlow } from '../../services/SourceSlice'
import { setUnableToUseBiometricsLogin } from '../../services/PhoneAuthStepTracker'
import { setSidebarActiveMenu } from '../../services/store/sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import StaticRoutePath from '../../routing/StaticRoutePath'
import CorporateMap from './CorporateMap'
import { useAppAlerts } from '../../utilities/alerts'
import { Dropdown } from 'react-bootstrap'
import { CustomDropDownToggle } from '../profile/components/CustomDropDownToggle'
import { Field, Form, Formik, FormikProps } from 'formik'
import { corporateLocationSearchValidationScheme } from '../login/FormHelper'
import { getCorporateBasedBtnColor, getTextColor } from './helpers'

interface SearchProps {
    global_on_id: string | undefined;
    user_id: string | number;
    search?: string;
    distance?: number;
    latitude?: number;
    longitude?: number;
}

export const CorporateLocationSearch = () => {
    const intl = useIntl();
    const [locations, setLocations] = useState<LocationProps[] | undefined>(undefined)
    const [selectedLocation, setSelectedLocation] = useState<LocationProps>()
    const GLOBAL_ON = process.env.REACT_APP_GLOBAL_ON
    const BASE_API_URL = process.env.REACT_APP_SP_API_URL;
    const { cookies_name, flow, errors } = constants;
    const dispatch = useDispatch<AppDispatch>()
    let navigateTo = useNavigate()
    const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral>();
    const [locationFrom, setLocationFrom] = useState<google.maps.LatLngLiteral>();
    const { fireErrorAlert, fireLoadingAlert } = useAppAlerts()
    const { corporate_name } = useParams()
    const [corporate, setCorporate] = useState<ICorporate | undefined>()
    const [corporate_id, user_id] = [corporate?.id, corporate?.owner]
    const [search, setSearch] = useState<string>('')
    const [distance, setDistance] = useState<number>(10)
    const [viewAllLocations, setViewAllLocations] = useState<boolean>(false)
    const formikRef = useRef<FormikProps<{ search: string }>>(null);

    const distanceOptions = [
        { id: 1, name: "1 miles" },
        { id: 5, name: "5 miles" },
        { id: 10, name: "10 miles" },
        { id: 15, name: "15 miles" },
        { id: 20, name: "20 miles" },
        { id: 25, name: "25 miles" },
    ];

    useEffect(()=>{
        if(corporate){
            document.documentElement.setAttribute('data-theme', corporate.appearance == 1 ? 'light' : 'dark')
            const root = document.documentElement
            root.style.setProperty('--kt-primary', corporate.primary_color)
            root.style.setProperty('--kt-primary-active', corporate.primary_color)
            root.style.setProperty('--kt-text-primary', corporate.primary_color)    
        }
    },[corporate])

    useEffect(() => {
        if(user_id)
            handleLocationsSearch()
    }, [distance])

    useEffect(()=>{
        if(user_id)
            if(currentLocation && search){
                resetSearch();
            }else{
                handleLocationsSearch()
            }
    }, [currentLocation])

    useEffect(() => {
        if(user_id)
            handleLocationsSearch(true)
    }, [search])

    //Get corporate id from corporate domain
    useEffect(() => {
        if (corporate_name) {
            if (corporate_name === 'all') {
                // setCorporateId(null)
                setCorporate(undefined)
                return
            }

            let config = {
                headers: {
                    'X-Skip-Interceptor': `true`,
                }
            }

            const API_URL = `${BASE_API_URL}/mobile/get-corporate-by-domain/${corporate_name}?global_on_id=${GLOBAL_ON}`;
            axios.get(`${API_URL}`, config)
                .then((response) => {
                    setCorporate(response.data)

                    if (Cookies.get(cookies_name.access_token) == undefined) {
                        let config = {
                            headers: {
                                Authorization: `Bearer ${Cookies.get(cookies_name.temp_access_token)}`,
                                'X-Skip-Interceptor': `true`,
                            }
                        }

                        console.log('temp config', config)
                        const API_URL = `${process.env.REACT_APP_SP_API_URL}/auth/mobile-login?global_on_id=${GLOBAL_ON}&corporate_id=${response?.data?.id}&user_id=${response?.data?.owner}`;
                        axios.post(`${API_URL}`, {}, config)
                            .then((response) => {
                                if (response?.data?.access_token == undefined) {
                                    Cookies.remove(cookies_name.temp_access_token)
                                    Cookies.remove(cookies_name.access_token)

                                    fireErrorAlert(intl.formatMessage({ id: "location.alert.unavailable" }));
                                } else {
                                    Cookies.set(cookies_name.access_token, response?.data?.access_token)
                                }
                            })
                            .catch((error) => {
                                Cookies.remove(cookies_name.temp_access_token)
                                Cookies.remove(cookies_name.access_token)

                                fireErrorAlert(intl.formatMessage({ id: "location.alert.offline" }))
                            })
                    }
                }).catch((error) => {
                    setCorporate(undefined)
                    fireErrorAlert(error.response.data?.error?.message ?? intl.formatMessage({ id: errors.static.default_error })).then((result) => {
                        console.log('Set No Corporate');
                    })
                });
        } else {
            setCorporate(undefined)
        }
    }, [corporate_name])

    const askForLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    const handleViewAllLocation = () => {
        if(search == "" && !currentLocation){
            handleLocationsSearch();
        }
        setCurrentLocation(undefined);
        resetSearch();
    }

    const resetSearch = () => {
        setSearch("");
        if (formikRef.current) {
            formikRef.current.setFieldValue('search', '');
            formikRef.current.setTouched({ search: false });
        }        
    }

    const redirectToOLO = (base_domain: string) => {
        dispatch(setFlow(flow.order))
        dispatch(setUnableToUseBiometricsLogin(false))
        dispatch(setSidebarActiveMenu(2))
        Cookies.set(cookies_name.my_account.my_account, 'no')
        navigateTo(base_domain + StaticRoutePath.OLO_MENU)
    }
    const setOrderingOfLocation = (location: LocationProps) => {
        setSelectedLocation(location)
        let config = {
            headers: {
                'X-Skip-Interceptor': `true`,
            }
        }
        fireLoadingAlert("Loading...")
        const API_URL = `${BASE_API_URL}/locations/${location.location_id}/mobile/settings?global_on_id=${GLOBAL_ON}`;
        axios.get(`${API_URL}`, config)
            .then((response) => {
                Swal.close();
                let base_domain = `/${location.location_id}`;
                Cookies.set(cookies_name.location_domain_name, location.location_id)
                if (response?.data?.domain_name && response?.data?.domain_name?.trim() !== '') {
                    Cookies.set(cookies_name.location_domain_name, response?.data?.domain_name)
                    base_domain = `/${response?.data.domain_name}`;

                    console.log("base_domain", base_domain)
                }

                console.log("base_domain", base_domain)

                dispatch(StoreLocationSettings({
                    ...response?.data,
                    'base_domain': base_domain
                }));
                setTimeout(() => {
                    redirectToOLO(base_domain);
                }, 1000);
            }).catch((error) => {
                console.log("error", error)
                fireErrorAlert(error.response.data?.error?.message ?? intl.formatMessage({ id: errors.static.default_error })).then((result) => {
                    console.log('Set No Location');
                })
            });
    }

    const handleLocationsSearch = (extendible?:boolean) => {
        fireLoadingAlert("Loading...")

        let params:SearchProps = {global_on_id: GLOBAL_ON, user_id: user_id ?? ""}
        if(search) {
            params.search = search
            params.distance = distance
        }else if(currentLocation){
            params.latitude = currentLocation.lat
            params.longitude = currentLocation.lng
            params.distance = distance
        }

        var API_URL = `${BASE_API_URL}/gis_search/${corporate_id ?? ''}`;        
        let config = {
            headers: {
                Authorization: `Barear ${Cookies.get(cookies_name.access_token)}`,
                'X-Skip-Interceptor': true,
            },
            params
        }

        axios.get(`${API_URL}`, config)
            .then((response) => {
                Swal.close();
                const resLocations:LocationProps[] = response?.data?.data
                .filter((location: LocationProps) => location.latitude || location.longitude)
                .map((location: LocationProps) => ({ ...location, latitude: Number(location.latitude), longitude: Number(location.longitude) }));
                setLocations(resLocations)
                if (!resLocations.length && extendible) {
                    setDistance(25)
                }
                if(response?.data?.location_from){
                    setLocationFrom({lat: Number(response?.data?.location_from.lat), lng: Number(response?.data?.location_from.lng)})       
                }
                
                if(resLocations.length > 0){
                    setSelectedLocation(resLocations[0])
                    if(search == "")
                        setLocationFrom({lat: resLocations[0].latitude, lng: resLocations[0].longitude})       
                }

                if(search == "")
                    setViewAllLocations(true);
            }).catch((error) => {
                if(search == "")
                    setViewAllLocations(true);
                if(error.response?.status != 404)
                    fireErrorAlert(error.response.data?.error?.message ?? intl.formatMessage({ id: errors.static.default_error }));
                setLocations([]);
                Swal.close();
            });
    }

    const changeDistance = (selectedDistance: string) => {
        setDistance(parseInt(selectedDistance));
    }

    return (
        <>
            <div className='page d-flex flex-row flex-column-fluid'>
                <Header corporate={corporate} selectedLocation={selectedLocation} />
                <Formik
                    innerRef={formikRef}
                    initialValues={{ search: search }}
                    validationSchema={corporateLocationSearchValidationScheme}
                    onSubmit={(values) => {
                        setSearch(values.search)
                        setViewAllLocations(false)
                        if(search == values.search){
                            if(distance != 25)
                                setDistance(25);
                            else
                                handleLocationsSearch()
                        }
                    }}
                >
                    {({ errors, touched }) => (
                        <Form className='w-100 p-0 m-0'>
                            <div className="container w-100 mw-100 m-0 p-0">
                                <div className="row d-flex w-100 h-100">
                                    <div className="col-lg-3 col-md-5" style={{ overflowY: "auto", height: "100vh", minWidth: "400px" }}
                                    >
                                        <div
                                            className='bg-body'
                                            style={{ paddingTop: '65px' }}
                                        >
                                            <div className='w-100 px-7 fs-1 fw-bold pt-10'>
                                                { corporate?.slogan && corporate.slogan}
                                                { !corporate?.slogan && "WELCOME" }
                                            </div>

                                            <div className='w-100 p-7'>
                                                <div className="mt-2 mb-2"><div><FormattedMessage id='corporate_landing.search_or_view' defaultMessage='Search or view nearby locations below' /></div></div>
                                                <div
                                                    className='col-12 d-flex align-items-center position-relative p-0 mt-5 mx-0 mb-0 py-3'
                                                    style={{ height: '65px' }}
                                                >
                                                    <i className='bi bi-search fs-3 position-absolute ms-4'>
                                                        <span className='path1'></span>
                                                        <span className='path2'></span>
                                                    </i>{' '}
                                                    <Field
                                                        type='text'
                                                        id='search'
                                                        name='search'
                                                        // onChange={(e) => { setSearch(e.target.value) }}
                                                        className='form-control form-control-lg form-control-solid w-100 ps-12 h-100 bg-page me-5'
                                                        placeholder={"Enter city, state or zip code"}
                                                    />
                                                    <button type='submit' className={`btn btn-sm btn-primary ${getCorporateBasedBtnColor(corporate)}`}>
                                                        <span className={`indicator-label`}><FormattedMessage id='common.search' defaultMessage='Search' /></span>
                                                    </button>
                                                </div>
                                                <div>
                                                    {touched.search && errors.search && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                {intl.formatMessage({ id: errors.search })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='text-center my-10 fw-semibold'>
                                                    <FormattedMessage id='corporate_landing.find_location_by' defaultMessage='To find a location, enter city, state, or zip code above.' /></div>
                                                <div className="d-flex flex-center w-100">
                                                    <div className="separator d-flex flex-center mb-8 text-center" style={{ width: '100px' }}>
                                                        <span className="text-uppercase bg-body fs-7 fw-bold text-muted px-3">or</span>
                                                </div></div>
                                                <div className='text-center mb-10' onClick={askForLocation}>
                                                    <a className={`menu-link text-center fw-semibold text-primary`} href="#"><span className={`${getTextColor(corporate)}`}> <FormattedMessage id='corporate_landing.use_mylocation' defaultMessage='Use my current location'/></span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`ms-2 bi bi-crosshair ${getTextColor(corporate)}`} viewBox="0 0 16 16">
                                                            <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7 7 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7 7 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7 7 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7 7 0 0 0 8.5 1.018zm-6.48 7A6 6 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6 6 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6 6 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6 6 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                                                        </svg></a></div>

                                                <div className="separator d-flex flex-center mb-8 text-center w-100">
                                                </div>
                                                {!viewAllLocations && (
                                                    <div className="d-flex flex-center w-100">
                                                        <button type='button' className=
                                                        {`btn btn-sm btn-primary mb-5 ${getCorporateBasedBtnColor(corporate)}`} onClick={handleViewAllLocation}>
                                                            <span className={`indicator-label`}><FormattedMessage id='corporate_landing.view_all_location' defaultMessage='View All Locations' /></span>
                                                        </button>
                                                    </div>
                                                )}
                                                {(!viewAllLocations || currentLocation) && locations && locations.length > 0 && (
                                                    <div className='d-flex h-50px align-items-center text-left mb-2 fw-semibold fs-6'>
                                                        Showing {locations.length} locations within
                                                        <Dropdown
                                                            className='mx-2'
                                                            onSelect={(key, event) => {
                                                                changeDistance(key!);
                                                            }}
                                                        >
                                                            <Dropdown.Toggle
                                                                as={CustomDropDownToggle}
                                                            >
                                                                <div
                                                                    className='form-select form-select-solid select2-hidden-accessible form-control-solid customInputBackground border'
                                                                >
                                                                    {distanceOptions.find((element) => element.id == distance)?.name}
                                                                </div>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu
                                                                className='fs-6 py-2'
                                                            >
                                                                {distanceOptions.map((item: any, index) => {
                                                                    return (
                                                                        <Dropdown.Item
                                                                            key={item.id}
                                                                            className={
                                                                                item.id == distance
                                                                                    ? 'activeCallCodeID genderMenuOptions'
                                                                                    : 'genderMenuOptions'
                                                                            }

                                                                            eventKey={item.id}
                                                                        >
                                                                            {item.name}
                                                                        </Dropdown.Item>
                                                                    )
                                                                })}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        of {search && search.length > 0 ? search : " your location"}:
                                                    </div>
                                                )}
                                                {locations && locations.length == 0 && (
                                                    <div><FormattedMessage id='corporate_landing.no_location_found' defaultMessage='No location found' /></div>
                                                )}
                                                <div className='mt-5'>
                                                    {locations?.map((location, index) => (
                                                        <LocationCard key={index} corporate={corporate!} location={location} isSelected={location.location_id == selectedLocation?.location_id} onSelect={(location) => {
                                                            setSelectedLocation(location)
                                                        }} onOrderPickeup={(locationSelected) => {
                                                            setOrderingOfLocation(locationSelected);
                                                        }}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="d-none d-md-block" style={{ flex: 1 }}>
                                        <CorporateMap
                                            corporate={corporate!}
                                            locations={locations ?? []}
                                            currentLocation={locationFrom}
                                            selectedLocation={selectedLocation}
                                            onOrderPickeup={(locationSelected) => setOrderingOfLocation(locationSelected)}
                                            onSelectMarker={(locationSelected) => setSelectedLocation(locationSelected)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}
