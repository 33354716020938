import { ICorporate, LocationProps } from "./_models";
import { AdvancedMarker, InfoWindow, Pin, useMap } from "@vis.gl/react-google-maps";
import { useCallback, useState, useRef, useEffect } from "react";
import { LocationCard } from "./LocationCard";

interface ICorporatePoiMarkers {
  corporate: ICorporate
  locations: LocationProps[]
  selectedLocation?: LocationProps
  onOrderPickeup: (location: LocationProps) => void
  onSelectMarker: (location: LocationProps) => void
}

export const CorporatePoiMarkers: React.FC<ICorporatePoiMarkers> = ({corporate, locations, selectedLocation, onOrderPickeup, onSelectMarker}) => {
  const map = useMap();
  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);
  const markerRefs = useRef<Map<string, google.maps.marker.AdvancedMarkerElement>>(new Map());
  const handleClose = useCallback(() => setSelectedMarker(null), []);
  const primaryColor = corporate?.primary_color;
  const secondaryColor = corporate?.primary_color == '#ffffff' ? "black" : "white" ;

  useEffect(() => {
    setTimeout(() => {
      if(selectedLocation){
        map?.panTo({ lat: selectedLocation.latitude, lng: selectedLocation.longitude});
        setSelectedMarker(selectedLocation.location_id.toString());   
      }             
    }, 10);
  }, [selectedLocation])
  

  const handleClick = useCallback((location: LocationProps, ev: google.maps.MapMouseEvent) => {
    if (!map || !ev.latLng) return;

    console.log('marker clicked:', ev.latLng.toString())
    onSelectMarker(location)
    setSelectedMarker(location.location_id.toString())
    map.panTo(ev.latLng)
  }, [map]);
  
  const createMarkerRef = useCallback((poiKey: string) => (marker: google.maps.marker.AdvancedMarkerElement | null) => {
    if (marker) {
      markerRefs.current.set(poiKey, marker);
    } else {
      markerRefs.current.delete(poiKey);
    }
  }, []);

  const pinBackground = (location:LocationProps) => {
    return(selectedMarker === location.location_id.toString() ? primaryColor : secondaryColor);
  }

  const pinGlyphColor = (location:LocationProps) => {
    return(selectedMarker === location.location_id.toString() ? secondaryColor : primaryColor);
  }
  return (
    <>
      {locations.map((location: LocationProps) => (
        <AdvancedMarker
          key={location.location_id.toString()}
          position={{ lat: location.latitude, lng: location.longitude}}
          clickable={true}
          ref={createMarkerRef(location.location_id.toString())}
          onClick={(ev) => handleClick(location, ev)}
        >
          <Pin background={pinBackground(location)} glyphColor={pinGlyphColor(location)} borderColor={primaryColor} scale={selectedMarker === location.location_id.toString() ? 1.4 : 1.0} />
          {selectedMarker === location.location_id.toString() && markerRefs.current.get(location.location_id.toString()) && (
            <InfoWindow anchor={markerRefs.current.get(location.location_id.toString())} onClose={handleClose}>
              <LocationCard corporate={corporate} location={location} onOrderPickeup={(locationSelected) => onOrderPickeup(locationSelected)} />
            </InfoWindow>
          )}
        </AdvancedMarker>
      ))}
    </>
  );
};

export default CorporatePoiMarkers;