import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {MenuCategoriesState, getMenuCategories, menuCategory} from "../store/ordering/menu/categories";

export interface MenuItem {
    id: string | number
    image: string | null;
    // Add other properties as needed
}

const MenuImageCacher = () => {
    const menuCategoriesState: MenuCategoriesState = useSelector((state: RootState) => state.menuCategories);
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const [itemInfoImageArray, setItemInfoImageArray] = useState<string[]>([]);
    const menuCategoriesData: menuCategory[] = menuCategoriesState.data
    const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON;
    const CACHE_NAME = `image-cache`;

    useEffect(() => {

        const items: MenuItem[] = [];
        const maxImageCacheCount = 6

        const fetchAndCacheImages = async () => {

            menuCategoriesData.forEach((data) => {
                // slice(start,end)
                // If end is greater than the length of the sequence,
                // slice extracts through to the end of the sequence (arr.length)
                items.push(...data._embedded.items.slice(0, maxImageCacheCount));
            });

            items.forEach((item) => {
                const itemInfoImage = `${process.env.REACT_APP_SP_API_URL}/download-file?path=${item.image}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}&cache=yes`;
                const params = new URLSearchParams(itemInfoImage.split("?")[1]);
                const searchQuery = params.get("path");
                if (!searchQuery?.includes("null")) {
                    itemInfoImageArray.push(itemInfoImage);
                }
            });

            const cache = await caches.open(CACHE_NAME);

            // For each URL in itemInfoImageArray the cache api will fetch and store.
            const results = await Promise.allSettled(itemInfoImageArray.map(url => {
                // console.log('PATH CHECK', url)
                return cache.add(new Request(url))
            }))

            // results.forEach(result => console.log(result.status))

            return results
        }


        const locationOwnerSet = LocSettings.location.owner != undefined
        if(menuCategoriesState?.data != undefined && menuCategoriesState.data[0] != undefined && menuCategoriesState.data[0].id != undefined) {
            const menuIsSet = menuCategoriesState.data[0].id != 0
            if (locationOwnerSet && menuIsSet) {
                setItemInfoImageArray([]);  // Clearing previous locations URLs

                // console.time('request-n-cache');
                setTimeout(() => {
                    fetchAndCacheImages()
                    .then(results => {
                        // console.log(results)
                        // console.timeEnd('request-n-cache')
                    })                    
                }, 1000);
            }
        }
    }, [LocSettings.location.owner, menuCategoriesState.isLoading]);

    return null;
};

export default MenuImageCacher;
