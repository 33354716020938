import { useState, useEffect } from 'react';
import { useErrorAlert, useLoadingAlert } from '../../../../utilities/alerts';
import * as ApiCallService from '../../../../services/ApiCallService'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom';
import { constants } from '../../../../config/constants';
import { setIframeSessionId } from '../../../../services/SourceSlice';

interface IframeSessionProps {
    loading: boolean;
    activateRegistrationSession: () => (void);
}

const useIframeSession = (): IframeSessionProps => {
   const intl = useIntl()
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const iframe_session_id = useSelector((state: RootState) => state.Source.iframe_session_id)
    const [loading, setLoading] = useState<boolean>(true);
    const fireErrorAlert = useErrorAlert()
    const fireLoadingAlert = useLoadingAlert()
    const navigateTo = useNavigate()
    const base_domain = LocSettings.base_domain
    const {routes} = constants;
    const dispatch = useDispatch<AppDispatch>()

    const activateRegistrationSession = () => {
        debugger
        setLoading(true);
        fireLoadingAlert("Loading...")
        ApiCallService.ApiCall({"register_session_id": iframe_session_id}, `/activate-registration-session`, 'POST', LocSettings)
        .then((res: any) => {
            setLoading(false);
            Swal.close()
            dispatch(setIframeSessionId(undefined))
            let callback_url = res?.data?.card_number
            if (callback_url){
                window.open(callback_url, '_self')
                return
            }else if (res.error !== undefined) {
                fireErrorAlert(res.error.message)
            }else{
                fireErrorAlert(intl.formatMessage({id:"common.alert.error_sending_data"}))
            }
            navigateTo(`${base_domain}/${routes.private.submission_review}`)
        })
        .catch((err) => {
            setLoading(false);
            Swal.close()
            dispatch(setIframeSessionId(undefined))
            fireErrorAlert(intl.formatMessage({id:"common.alert.error_sending_data"}))
            navigateTo(`${base_domain}/${routes.private.submission_review}`)
        })
    };

    return { loading, activateRegistrationSession };
}

export default useIframeSession;
