import { ICorporate } from "./_models"

export const getCorporateBasedBtnColor = (corporate?: ICorporate) => {
    if (
        corporate?.appearance === 1 &&
        corporate?.background_color == '#ffffff' &&
        corporate?.primary_color == '#ffffff'
    ) {
        return 'text-black'
    } else if (
        corporate?.appearance === 0 &&
        corporate?.background_color == '#151521' &&
        corporate?.primary_color == '#151521'
    ) {
        return 'text-white '
    } else if (
        corporate?.appearance === 0 &&
        corporate?.primary_color == '#ffffff' &&
        corporate?.background_color == '#ffffff'
    ) {
        return 'text-black'
    } else if (
        corporate?.appearance === 1 &&
        corporate?.primary_color == '#151521' &&
        corporate?.background_color == '#151521'
    ) {
        return 'text-white'
    } else if (corporate?.appearance === 0 && corporate?.primary_color == '#ffffff') {
        return 'text-black'
    } else if (corporate?.appearance === 0 && corporate?.primary_color == '#151521') {
        return 'text-white'
    } else if (corporate?.appearance === 1 && corporate?.primary_color == '#ffffff') {
        return 'text-white'
    } else if (corporate?.appearance === 1 && corporate?.primary_color == '#151521') {
        return 'text-white'
    } else {
        return ''
    }
}

export const getTextColor = (corporate?: ICorporate) => {
    if (corporate?.appearance === 1 && corporate?.primary_color == '#151521') {
        return 'text-black'
    } else if (corporate?.appearance === 0 && corporate?.primary_color == '#ffffff') {
        return 'text-white'
    }
    return ''
}

export const getBorderColor = (corporate?: ICorporate) => {
    if (corporate?.appearance === 1 && corporate?.primary_color == '#ffffff') {
        return '#000000'
    } else if (corporate?.appearance === 0 && corporate?.primary_color == '#151521') {
        return '#ffffff'
    } else {
        return corporate?.primary_color
    }
} 