import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { getOloSchedual, getOloSettings } from '../common/parsers'
import moment from 'moment'
import { LocationScheduleDayState } from '../state/LocationSchedualState'
import {
   setIsLocationScheduleAvailable,
   setLocationScheduleToday,
} from '../../../services/store/LocationSchedual'
import { FormattedMessage, useIntl } from 'react-intl'

interface ScheduleEvent {
   id: number;
   type: 0 | 1;
   start_time: string;
   end_time: string;
   from_local: string;
   to_local: string;
   message: string;
}

export const StoreClosedBanner = () => {
   const intl = useIntl()
   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const [orderAlertShow, setOrderAlertShow] = useState<boolean>(false)
   const [nextScheduleDay, setNextScheduleDay] = useState<string | null>(null)
   const [nextScheduleDayOpenTime, setNextScheduleDayOpenTime] = useState<string | null>(null)
   const [openTill, setOpenTill] = useState<string>('')
   const [closeMessage, setCloseMessage] = useState<string>('')

   const dispatch = useDispatch<AppDispatch>()

   const OloSchedual = getOloSchedual(LocSettings)

   const currentDay = moment().locale('en').format('dddd').toLowerCase()
   const timeFormat = 'HH:mm:ss'
   var currentTimeString = moment().format(timeFormat)
   const oloSettings = getOloSettings(LocSettings)
   const scheduleEvents = LocSettings.schedule_events;

   // let oloOpen = false;
   let dayDetails: LocationScheduleDayState | null = null
   let nextDayDetails: LocationScheduleDayState | null = null

   function getLocalDateTime() {
      const date = new Date(LocSettings.location.localTime);

      const sourceOffset = date.toLocaleString('en-US', { timeZone: LocSettings.location.localTimeZone });
      const sourceDate = new Date(sourceOffset);

      const localOffset = date.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
      const localDate = new Date(localOffset);

      const offsetDifference = localDate.getTime() - sourceDate.getTime();

      const convertedDate = new Date(date.getTime() + offsetDifference);
      return convertedDate;
   }

   const determineStoreStatus = (): LocationScheduleDayState | null => {
      const currentTimeDateTime = getLocalDateTime();
      const currentTime = moment(currentTimeDateTime);
      currentTimeString = currentTime.format(timeFormat)
      let activeEvent: ScheduleEvent | null = null;
      
      // Find the first active event where the current time is within the event's window
      for (const event of scheduleEvents) {
         const eventStart = moment(event.from_local).startOf('day').add(event.start_time, 'seconds');
         const eventEnd = moment(event.to_local).endOf('day').set({
            hour: parseInt(event.end_time.split(':')[0]),
            minute: parseInt(event.end_time.split(':')[1]),
            second: parseInt(event.end_time.split(':')[2]),
         });

         if (currentTime.isBetween(eventStart, eventEnd)) {
            activeEvent = event;
            break; // Stop at the first matching event
         }
      }

      if (activeEvent) {
         // If an active event is found, return its status and times
         const eventEnd = moment(activeEvent.to_local).endOf('day').set({
            hour: parseInt(activeEvent.end_time.split(':')[0]),
            minute: parseInt(activeEvent.end_time.split(':')[1]),
            second: parseInt(activeEvent.end_time.split(':')[2]),
         });
         return {
            status: activeEvent.type,
            start_time: moment(activeEvent.start_time, 'HH:mm:ss').format('HH:mm:ss'),
            end_time: moment(activeEvent.end_time, 'HH:mm:ss').format('HH:mm:ss'),
            end_date: eventEnd.toString(),
            message: activeEvent.message
         };
      } else {
         // If no active event, fall back to OloSchedual
         let dayDetails: LocationScheduleDayState | null = null;

         Object.entries(OloSchedual).forEach((day) => {
            if (day[0] === currentDay) {
               dayDetails = day[1];
            }
         });

         return dayDetails;
      }
   };

   const findSchedule = (currentDay: string) => {
      if (OloSchedual) {

         const dayDetails = determineStoreStatus();
         if (dayDetails && dayDetails?.status == 1) {
            var beginningTime = moment(currentTimeString, timeFormat)
            const beforeTime = dayDetails?.start_time
            const afterTime = dayDetails?.end_time
            let momentAfter = moment(afterTime, timeFormat);
            let timeValidation = beginningTime.isBetween(
               moment(beforeTime, timeFormat),
               moment(afterTime, timeFormat)
            )
            if (dayDetails?.end_date) {
               momentAfter = moment(dayDetails.end_date);
               timeValidation = beginningTime.isBetween(moment(beforeTime, timeFormat), momentAfter);
            }
            const checkBeforeOpen = beginningTime.isBefore(moment(beforeTime, timeFormat))
            if (timeValidation) {
               const endTime = moment(afterTime, timeFormat);
               if (dayDetails?.end_date && !moment(dayDetails.end_date).isSame(moment(), "day")) {
                  setOpenTill(" ")
               } else
                  setOpenTill(endTime.format("hh:mm A"))
            }
            if (checkBeforeOpen || timeValidation) {
               dispatch(setIsLocationScheduleAvailable(true))
               dispatch(setLocationScheduleToday(true))
            }
            if (checkBeforeOpen) {
               setNextScheduleDay('today')
               setNextScheduleDayOpenTime(moment(beforeTime, timeFormat).format('hh:mm A'))
               dispatch(setIsLocationScheduleAvailable(true))
               setOrderAlertShow(true)
            } else if (!timeValidation) {
               setOrderAlertShow(true)
               findNextSchedule(currentDay, 1)
            }
         } else {
            if (dayDetails && dayDetails?.status == 0 && dayDetails?.end_date) {
               setCloseMessage(dayDetails?.message ?? "");
               setOrderAlertShow(true)
            } else {
               setOrderAlertShow(true)
               findNextSchedule(currentDay, 1)
            }
         }
      }
   }
   const findNextSchedule = (currentDay: string, daycount: number) => {
      dispatch(setLocationScheduleToday(false))

      if (daycount > 6) {
         dispatch(setIsLocationScheduleAvailable(false))
         return
      }

      const nextDay = moment().locale("en").add(daycount, 'days').format('dddd')
      if ((oloSettings?.schedule_orders_days ?? 1) < daycount) {
         dispatch(setIsLocationScheduleAvailable(false))
      }
      Object.entries(OloSchedual).map((day) => {
         if (day[0] == nextDay.toLowerCase()) {
            nextDayDetails = day[1]
         }
      })
      if (nextDayDetails && nextDayDetails.status == 1) {
         setNextScheduleDay(daycount == 1 ? "Tomorrow" : nextDay)
         setNextScheduleDayOpenTime(moment(nextDayDetails.start_time, timeFormat).format('hh:mm A'))
         setOrderAlertShow(true)
         if ((oloSettings?.schedule_orders_days ?? 1) >= daycount) {
            dispatch(setIsLocationScheduleAvailable(true))
         }
      } else {
         findNextSchedule(currentDay, daycount + 1)
      }
   }

   useEffect(() => {
      findSchedule(currentDay)
   }, [])

   return (
      <>{orderAlertShow && (
         <span className='text-danger fw-bold'>
            {nextScheduleDayOpenTime == null && nextScheduleDay == null ? (
               <>
                  {LocSettings.location.location_name}{' '}
                  <FormattedMessage
                     id='olo.store.currently_not_open'
                     defaultMessage='is currently not open'
                  />
                  <div>
                     {closeMessage}
                  </div>
               </>
            ) : (
               <>
                  <FormattedMessage
                     id='olo.store.will_open_at'
                     defaultMessage='is currently not open and will open at'
                  />{' '}
                  {nextScheduleDayOpenTime} {nextScheduleDay && nextScheduleDay != "today" && (
                     intl.formatMessage({ id: 'common.on' }) && ' ' && nextScheduleDay
                  )}
               </>
            )}
         </span>
      )}
         {!orderAlertShow && openTill == " " && (
            <span className='text-success fw-bold' >
               <FormattedMessage
                  id='olo.store.open'
                  defaultMessage='Open'
               />
            </span>
         )}
         {!orderAlertShow && openTill.trim() != "" && (
            <span className='text-success fw-bold' >
               <FormattedMessage
                  id='olo.store.open_till'
                  defaultMessage='Open till'
               />{' '} {openTill}
            </span>
         )}
      </>
   )
}
