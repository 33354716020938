import * as ApiCallService from "../../../../ApiCallService";
import axios from "axios";
import { setMenuModifierGroups, startLoading } from "./menuModifierGroupsSlice";
import { IModifierGroup } from "../../../../../pages/online_ordering/state/CartState";
import { IModifiers } from "../../../../../pages/online_ordering/state/ModGroupState";

export const getMenuModifierGroups = (LocSettings: any) => {
    return async (dispatch: (arg0: { payload: any; type: string; }) => void, state: any) => {
        dispatch(startLoading())
        ApiCallService.ApiCall({}, `app/menu/modifier_groups`, 'GET', LocSettings)
            .then((res: any) => {
                if(res?.error?.message != undefined) {
                    // dispatch( setMenuModifierGroups(res.error) )
                } else {
                    dispatch( setMenuModifierGroups(res.data) )
                }
        }).catch((err: any) => {
            dispatch( setMenuModifierGroups({}) )
        });
    }
}

export const validateModifiersSelection = (filters: IModifierGroup[], selection: IModifierGroup[], parentModifierId?: null | number, parentModGroupId?: null | number) => {
    return filters.length > 0 && filters.some((modGroupinfo: IModifierGroup, index: number) => {
        const checkingMatchingItem =
            selection &&
            selection.filter((item: IModifierGroup) =>
                modGroupinfo.modifiers && modGroupinfo.modifiers.some(
                    (i: IModifiers) => i.id === item.id && item.modGroupId === modGroupinfo.id && item.parent_modifier_id == parentModifierId && item.parent_modifier_group_id == parentModGroupId
                )
            )
        const count = checkingMatchingItem.length

        let minimum = modGroupinfo.minimum ?? 0
        let maximum = modGroupinfo.maximum && minimum < modGroupinfo.maximum ? modGroupinfo.maximum : minimum

        if (modGroupinfo.option_type == 1 && minimum != 0 && maximum != 0) {
        if (count < Number(minimum) || count > Number(maximum)) {
            return true
        }
        } else if (modGroupinfo.option_type != 1 && minimum != 0 && maximum != 0 && count > 0) {
        if (count < Number(minimum) || count > Number(maximum)) {
            return true
        }
        }
    })
}

