import {APIProvider, Map} from "@vis.gl/react-google-maps"
import {ICorporate, LocationProps} from "./_models";
import CorporatePoiMarkers from "./CorporatePoiMarkers";
import MapHandler from "./MapHandler";

interface ICorporateMap {
    corporate: ICorporate
    locations: LocationProps[]
    currentLocation?: google.maps.LatLngLiteral
    selectedLocation?: LocationProps;
    onOrderPickeup: (location: LocationProps) => void
    onSelectMarker: (location: LocationProps) => void
}

export const CorporateMap: React.FC<ICorporateMap> = ({corporate, locations, currentLocation, selectedLocation, onOrderPickeup, onSelectMarker}) => {
    const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
    const MAP_ID = process.env.REACT_APP_GOOGLE_MAPS_MAP_ID as string;

    let default_lat = 33.493297, default_lng = -111.9355987
    
    return <APIProvider apiKey={MAPS_API_KEY}>
        <Map
            style={{height: '100vh'}}
            defaultCenter={{lat: default_lat, lng: default_lng}}
            defaultZoom={13}
            mapId={MAP_ID}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            clickableIcons={false}
        >
            <CorporatePoiMarkers corporate={corporate} locations={locations} selectedLocation={selectedLocation} onOrderPickeup={onOrderPickeup} onSelectMarker={onSelectMarker} />
        </Map>
        <MapHandler currentLocation={currentLocation} />
    </APIProvider>
}

export default CorporateMap