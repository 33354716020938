import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {constants} from "../config/constants";

export interface Source {
    flow: string|null,
    sub_flow: string|null,
    business_source: number|null,
    payment_source: string|null,
    profile_auth: string|null,
    loyalty_enrollment: string|null,
    iframe_session_id: string|null
}

const initialState: Source = {
    flow: constants.flow.profile,
    sub_flow: null,
    business_source: null,
    payment_source:null,
    profile_auth:null,
    loyalty_enrollment:null,
    iframe_session_id: null
}

export const SourceSlice = createSlice({
    name: 'flow',
    initialState,
    reducers: {
        setFlow: (state, action: PayloadAction<any>) => {
            state.flow = action.payload
        },
        setSubFlow: (state, action: PayloadAction<any>) => {
            //Use it when you need to add a sub flow like: flow = order + subFlow = table
            state.sub_flow = action.payload
        },
        setBusinessSource: (state, action: PayloadAction<any>) => {
            state.business_source = action.payload
        },
        setProfileAuth: (state, action: PayloadAction<any>) => {
            state.profile_auth = action.payload
        },
        setPaymentSource: (state, action: PayloadAction<any>) => {
            state.payment_source = action.payload
        },
        setLoyaltyEnrollment: (state, action: PayloadAction<any>) => {
            state.loyalty_enrollment = action.payload
        },
        setIframeSessionId: (state, action: PayloadAction<any>) => {
            state.iframe_session_id = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setFlow,
    setSubFlow,
    setBusinessSource,
    setPaymentSource,
    setProfileAuth,
    setLoyaltyEnrollment,
    setIframeSessionId
} = SourceSlice.actions

export default SourceSlice.reducer
