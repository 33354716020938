import {useMap} from "@vis.gl/react-google-maps"
import {useEffect} from "react"

interface IMapHanlder {
    currentLocation?: google.maps.LatLngLiteral
}

export const MapHandler: React.FC<IMapHanlder> = ({currentLocation}) :any => {
    const map = useMap()

    useEffect(() => {
        if (!currentLocation) return

        console.log('current location')
        map?.panTo(currentLocation)
        map?.setZoom(11)
    }, [currentLocation])
}

export default MapHandler